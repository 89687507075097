import * as validationFunc from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export const formBuilderMixin = {
  data() {
    return {
      //***GET THESE LANGUAGES FROM VUEX STATE INSTEAD OF HARDCODED HERE, THIS IS TEMPORARY***
      form: {},
      serializedIncomingForm: null
    };
  },
  created: function () {},
  computed: {
    ...mapGetters(["getLanguages", "getTabLanguage"]),

    isFormUpdated() {
      let vm = this;

      return vm.serializedIncomingForm !== JSON.stringify(vm.form);
    }
  },
  methods: {
    generateFormOutOfSchemaJson(schemaJson, myModel = {}) {
      let vm = this;

      return new Promise((resolve) => {
        schemaJson["fields"].forEach(function (item) {
          if (
            vm.checkIfItemTranslatable(item) &&
            item.type !== "dynamic-input"
          ) {
            vm.getLanguages.forEach(function (lang) {
              let key = item.model + ":" + lang.lang;
              let value = myModel[key];
              if (value === undefined) {
                value = item.default !== undefined ? item.default : "";
              }

              vm.$set(vm.form, key, value);
            });
          } else {
            let key = item.model;
            let value = myModel[key];
            if (value === undefined) {
              value =
                item.default !== undefined
                  ? item.default
                  : item.type !== "dynamic-input"
                  ? ""
                  : [];
            }

            vm.$set(vm.form, key, value);
          }
        });

        resolve();
      });
    },

    beforeunloadHandler(event) {
      let vm = this;

      if (vm.isEdit && vm.isFormUpdated) {
        event.preventDefault();

        return (event.returnValue = "Changes you made may not be saved.");
      }
    },

    prepareChangesCheck() {
      return new Promise((resolve) => {
        let vm = this;

        vm.serializedIncomingForm = JSON.stringify(vm.form);

        window.addEventListener("beforeunload", vm.beforeunloadHandler);

        resolve();
      });
    },

    generateFormValidationsOutOfSchemaJson(schemaJson) {
      let vm = this;
      let formValidation = { form: {} };
      schemaJson["fields"].forEach(function (item) {
        if (vm.checkIfItemTranslatable(item)) {
          if (item.type !== "dynamic-input") {
            vm.getLanguages.forEach(function (lang) {
              formValidation.form[item.model + ":" + lang.lang] =
                vm.setItemValidations(item.validations);
            });
          } else {
            formValidation.form[item.model] = vm.setItemValidations(
              item.validations,
              vm.getLanguages
            );
          }
        } else {
          formValidation.form[item.model] = vm.setItemValidations(
            item.validations
          );
        }
      });

      return formValidation;
    },
    setItemValidations(validations, languages = undefined) {
      var itemValidationRules = {};

      for (const [key, value] of Object.entries(validations)) {
        itemValidationRules[key] = this.setValidationRule(
          key,
          value,
          languages
        );
      }

      return itemValidationRules;
    },
    setValidationRule(key, value, languages = undefined) {
      var validationRule;
      switch (key) {
        case "required":
          if (value) {
            validationRule = validationFunc.required;
          } else {
            validationRule = {};
          }
          break;
        case "email":
          validationRule = validationFunc.email;
          break;
        case "minLength":
          validationRule = validationFunc.minLength(value);
          break;
        case "maxLength":
          validationRule = validationFunc.maxLength(value);
          break;
        case "numeric":
          validationRule = validationFunc.numeric;
          break;
        case "decimal":
          validationRule = validationFunc.decimal;
          break;
        case "minValue":
          validationRule = validationFunc.minValue(value);
          break;
        case "maxValue":
          validationRule = validationFunc.maxValue(value);
          break;
        case "$each":
          validationRule = {};
          for (const [key, value] of Object.entries(value)) {
            if (languages) {
              languages.forEach((lang) => {
                validationRule[`${key}:${lang.lang}`] =
                  this.setItemValidations(value);
              });
            } else {
              validationRule[key] = this.setItemValidations(value);
            }
          }

          break;
        default:
          validationRule = validationFunc.required;
        // code block
      }
      return validationRule;
    },
    checkIfItemTranslatable(item) {
      return item.translatable; // && item.type !== "dynamic-input";
    }
  },
  validations() {
    return this.generateFormValidationsOutOfSchemaJson(this.schemaJson);
  },

  beforeRouteLeave(to, from, next) {
    let vm = this;

    if (vm.isEdit && vm.isFormUpdated) {
      if (confirm("Changes you made may not be saved.")) {
        next();
      }

      return;
    }

    next();
  },

  beforeDestroy() {
    let vm = this;

    window.removeEventListener("beforeunload", vm.beforeunloadHandler);
  }
};
