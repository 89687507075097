<template>
  <v-card-title>
    <slot name="prepend"></slot>
    <v-text-field
      v-if="searchableText"
      v-model="searchText"
      :label="$t('SEARCH')"
      v-on:keyup.enter="$emit('do-search')"
      single-line
      hide-details
    ></v-text-field>
    <v-tooltip color="primary" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="$emit('do-search')"
        >
          <v-icon dark>search</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("SEARCH") }}</span>
    </v-tooltip>
    <v-tooltip v-if="clearable" color="red" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="red"
          @click="$emit('do-clear')"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("CLEAR") }}</span>
    </v-tooltip>
  </v-card-title>
</template>

<script>
//General
export default {
  name: "SearchTools",
  inheritAttrs: false,
  props: {
    searchQuery: {
      type: String,
      default: ""
    },
    searchableText: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  components: {},
  watch: {},
  beforeMount() {},
  computed: {
    searchText: {
      get: function() {
        return this.searchQuery;
      },
      set: function(newValue) {
        this.$emit("update:searchQuery", newValue);
      }
    }
  },
  methods: {}
};
</script>
