<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          {{ $t("KITCHEN_EQUIPMENTS") + ": " + storeName }}
        </div>

        <div class="card-toolbar">
          <v-dialog :retain-focus="false" v-model="dialogAdd" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon2-plus"></i>
                {{ $t("ADD_NEW") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("KITCHEN_EQUIPMENT") + " - " + storeName
                }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="form.name"
                        :label="$t('NAME')"
                        hide-details
                        single-line
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="kitchenEquipmentTypes"
                        :label="$t('KITCHEN_EQUIPMENT_TYPE')"
                        item-text="name"
                        item-value="id"
                        hide-details
                        v-model="form.kitchenEquipmentsTypeId"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog :retain-focus="false" v-model="dialogPDF" max-width="900px">
            <template v-slot:activator="{ on, attrs }">
              <v-progress-circular
                v-show="isPDFloading"
                indeterminate
                color="green"
              ></v-progress-circular>
              <v-btn
                v-show="!isPDFloading"
                color="btn-success"
                class="btn btn-light-success font-weight-bolder mr-2 mt-3"
                v-bind="attrs"
                v-on="on"
              >
                <i class="flaticon-download"></i>
                {{ $t("DNEVNIK_TR") }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{
                  $t("KITCHEN_EQUIPMENT") + " - " + storeName
                }}</span>
              </v-card-title>

              <v-card-text>
                <b-col>
                  <FormDatepicker
                    v-model="dates"
                    :type="'text'"
                    :clearable="true"
                    :i18n="{
                      label: 'DATE'
                    }"
                  ></FormDatepicker>
                </b-col>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="getPDF()">
                  Генерирай
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :sort-desc="false"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingKitchenEquipments"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="kitchen-equipments-table"
        >
          <v-divider inset></v-divider>
          <template v-slot:top>
            <SearchTools
              :searchQuery.sync="searchQuery"
              @do-search="doSearch"
              @do-clear="doClear"
            >
              <template v-slot:prepend>
                <!--                <b-col>-->
                <!--                  <FormDatepickerBase-->
                <!--                    :type="'text'"-->
                <!--                    :clearable="true"-->
                <!--                    :i18n="{-->
                <!--                      label: 'USERS_SCHEDULE_DATE'-->
                <!--                    }"-->
                <!--                    :date.sync="filters.workDay"-->
                <!--                  ></FormDatepickerBase>-->
                <!--                </b-col>-->
                <b-col>
                  <v-autocomplete
                    v-if="_.size(stores) > 0"
                    :items="stores"
                    :label="$t('STORE')"
                    item-text="name"
                    item-value="id"
                    hide-details
                    clearable
                    v-model="filters.storeId"
                    @click:clear="clearFilter('storeId')"
                  ></v-autocomplete>
                </b-col>
              </template>
            </SearchTools>
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.kitchenEquipmentType="{ item }">
            {{ item.kitchenEquipmentType }}
          </template>

          <template v-slot:item.store="{ item }">
            {{ item.store }}
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-chip :color="getColor(item.isActive)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.isActive) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.isActive) }}</v-chip
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <v-btn class="mr-12" fab x-small>
                    <v-icon color="red" @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
                <div class="col-md-4 col-sm-12">
                  <v-dialog
                    v-model="dialogEdit"
                    max-width="900px"
                    :retain-focus="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-1"
                        v-bind="attrs"
                        v-on="on"
                        fab
                        dark
                        x-small
                        @click="edit(item)"
                      >
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{
                          $t("KITCHEN_EQUIPMENT") + " - " + storeName
                        }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                v-model="editForm.name"
                                :label="$t('NAME')"
                                hide-details
                                single-line
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-select
                                :items="kitchenEquipmentTypes"
                                :label="$t('KITCHEN_EQUIPMENT_TYPE')"
                                item-text="name"
                                item-value="id"
                                hide-details
                                v-model="editForm.kitchenEquipmentsTypeId"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-checkbox
                                label="Активен"
                                v-model="editForm.isActive"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="update()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <div class="col-md-4 col-sm-12">
                  <router-link
                    v-if="checkIfUserHasPermission(['stores-update'])"
                    :to="{
                      name: 'temperature-kitchen-equipments',
                      params: { id: item.id }
                    }"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ml-2"
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="blue">mdi-temperature-celsius</v-icon>
                        </v-btn>
                      </template>
                      <span>Температури</span>
                    </v-tooltip>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<script>
import {
  FETCH_KITCHEN_EQUIPMENTS,
  SAVE_KITCHEN_EQUIPMENT,
  UPDATE_KITCHEN_EQUIPMENT,
  DELETE_KITCHEN_EQUIPMENT,
  GET_KITCHEN_EQUIPMENT_TEMPERATURE_PDF
} from "@/modules/kitchen-equipments/store/kitchen-equipments.module";

import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import { mapGetters } from "vuex";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin";
import moment from "moment/moment";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";

export default {
  name: "KitchenEquipmentList",
  mixins: [permissionMixin, formBuilderMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      isPDFloading: false,
      firstLoader: true,
      search: "",
      dialogPDF: false,
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      items: [],
      stores: [],
      dates: [],
      kitchenEquipmentTypes: [],
      totalItems: 100,
      searchQuery: "",
      storeName: "",
      form: {
        name: "",
        kitchenEquipmentsTypeId: null
      },
      editForm: {
        id: "",
        name: "",
        kitchenEquipmentsTypeId: null,
        storeId: null,
        isActive: null
      },
      filters: {
        name: "",
        storeId: null,
        measuredOn: null
      },
      options: {
        descending: false,
        page: 1,
        itemsPerPage: -1,
        totalItems: 100
      },
      defaultItem: {
        id: 0,
        name: "",
        kitchenquipmentsType: "",
        store: "",
        isActive: ""
      },
      rowsPerPageItems: [-1, 10],
      columns: {},
      headers: [
        { text: "Name", value: "name", sortable: false },
        {
          text: "Equipment Type",
          value: "kitchenEquipmentType",
          sortable: false
        },
        { text: "Store", value: "store", sortable: false },
        { text: "Active", value: "isActive", sortable: false },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px"
        }
      ]
    };
  },
  components: {
    FormDatepicker,
    SearchTools
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Kitchen Equipments",
        route: { name: "list-kitchen-equipments" }
      },
      { title: "Kitchen Equipments List" }
    ]);
    vm.getSelectsStores();
    vm.getSelectsKitchenEquipments();
  },
  computed: {
    ...mapGetters(["getKitchenEquipments", "isLoadingKitchenEquipments"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        storeId: this.filters.storeId,
        measuredOn: this.filters.measuredOn
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  watch: {
    dialogPDF(val) {
      val || this.close();
    },
    dialogAdd(val) {
      val || this.close();
    },
    dialogEdit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    close() {
      let vm = this;
      vm.dialogPDF = false;
      vm.dialogAdd = false;
      vm.dialogEdit = false;
    },
    closeDelete() {
      let vm = this;
      vm.dialogDelete = false;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    edit(item) {
      this.editForm = Object.assign({}, item);
      this.dialogEdit = true;
    },
    deleteItemConfirm() {
      let vm = this;
      this.$store
        .dispatch(DELETE_KITCHEN_EQUIPMENT, vm.defaultItem.id)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            this.closeDelete();
            this.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
      this.doSearch();
    },
    save() {
      let vm = this;
      let storeId;

      if (vm.filters.storeId === "" || vm.filters.storeId === null) {
        storeId = vm.stores[0].id;
      } else {
        storeId = vm.filters.storeId;
      }

      let payload = {
        name: vm.form.name,
        kitchen_equipment_type_id: vm.form.kitchenEquipmentsTypeId,
        store_id: storeId
      };

      this.$store
        .dispatch(SAVE_KITCHEN_EQUIPMENT, payload)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    update() {
      let vm = this;
      let id = vm.editForm.id;
      let payload = {
        name: vm.editForm.name,
        kitchen_equipment_type_id: vm.editForm.kitchenEquipmentsTypeId,
        store_id: vm.editForm.storeId,
        isActive: vm.editForm.isActive
      };

      this.$store
        .dispatch(UPDATE_KITCHEN_EQUIPMENT, { id: id, payload: payload })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.$nextTick(function() {
            vm.close();
            vm.doSearch();
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            vm.close();
          }
        });
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.storeName = vm.stores[0].name;
      vm.workDayDate = moment().format("YYYY-MM-DD");
      vm.searchQuery = "";
      vm.filters.storeId = "";
      vm.dates = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getPDF() {
      let vm = this;
      vm.isPDFloading = true;
      //copy current params to modify
      vm.isLoadingStoreDailyTasks = true;
      let datePDF = vm.dates;
      let params = this.params;
      params.dateRange = vm.dates;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(GET_KITCHEN_EQUIPMENT_TEMPERATURE_PDF, apiParams)
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            let blob = new Blob([data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "store-tasks-" + vm.storeName + "-" + datePDF;
            link.click();
            link.remove();
            vm.dialogPDF = false;
            if (vm.isPageLoading == true || vm.isPDFloading == true) {
              vm.isPageLoading = false;
              vm.isPDFloading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getDataFromApi() {
      let vm = this;

      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;
      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_KITCHEN_EQUIPMENTS, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.fetchStoreName();
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
          }
        });
    },
    getSelectsKitchenEquipments() {
      let vm = this;
      let payload = {
        kitchen_equipment_types: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.kitchenEquipmentTypes = data.data.kitchen_equipment_types;
        })
        .catch(response => {
          console.log(response);
        });
    },
    getSelectsStores() {
      let vm = this;
      let payload = {
        stores: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.stores = data.data.stores;
          vm.filters.storeId = data.data.stores[0].id;
          vm.fetchStoreName();
        })
        .catch(response => {
          console.log(response);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    },
    fetchStoreName() {
      let vm = this;
      if (vm.filters.storeId == "" || vm.filters.storeId == null) {
        if (vm.stores.length != 0) {
          vm.storeName = vm.stores[0].name;
        }
      } else {
        vm.storeName = vm.stores.find(function(store) {
          return store.id === vm.filters.storeId;
        }).name;
      }
    }
  }
};
</script>
