<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="dates"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="$t(i18n.label)"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      scrollable
      range
      :first-day-of-week="1"
      :locale="activeLanguage"
      :selected-items-text="selectedItemsText"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="submit"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";
export default {
  name: "FormDatepicker",
  inheritAttrs: false,
  props: {
    /**
     * All Translation i18n keys used
     */
    i18n: Object,
    /**
     * V-Model
     */
    value: {}
  },
  data() {
    return {
      dates: [],
      modal: false
    };
  },
  components: {},
  beforeMount() {
    this.initializeDates();
  },
  mounted() {},
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
      // return "asd";
    },
    selectedItemsText() {
      if (_.size(this.dates) == 2) {
        var start = moment(this.dates[0]);
        var end = moment(this.dates[1]);
        var numberOfDays = Math.abs(end.diff(start, "days"));
        return parseFloat(numberOfDays + 1) + " " + this.$t("DAYS");
      } else {
        return "TEST";
      }
    },
    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
  },
  methods: {
    initializeDates() {
      this.dates = this.value;
    },
    submit(closePicker = true) {
      this.$refs.dialog.save(this.dates);
      let selectedRange = this.dates;
      if (closePicker) {
        this.modal = false;
      }
      this.$emit("input", selectedRange);
    }
  }
};
</script>
